@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

body {
  line-height: 1.5;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 * {
   box-sizing: border-box;
 }